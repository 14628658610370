:root{
  --green: #5EB34A;
  --green-transparent: #5db34a8b;
  --green-transparent-08: #5db34aab;
  --white: #E8ECE5;
  --gray: #424342;
  --gray-transparent: #42434284;
  --gray-transparent-08: #424342e7;
  --black: #000;
  --off-white: #fff;
  --yellow: #FCD757;
}
/*GOPHER*/
@font-face {
  font-family: 'Gopher-Light';
  src: url(../fonts/Gopher/Gopher-Light.otf);
}
@font-face {
  font-family: 'Gopher-Dm';
  src: url(../fonts/Gopher/Gopher-Medium.otf);
}
@font-face {
  font-family: 'Gopher-Medium';
  src: url(../fonts/Gopher/Gopher-Medium.otf);
}
@font-face {
  font-family: 'Gopher-Display';
  src: url(../fonts/Gopher/GopherDisplay-Regular.otf);
}
@font-face {
  font-family: 'Gopher-Bold';
  src: url(../fonts/Gopher/Gopher-Bold.otf);
}

@font-face {
  font-family: 'Gopher-Semibold';
  src: url(../fonts/Gopher/GopherText-Bold.otf);
}

/*OPEN SANS*/
@font-face {
  font-family: 'OpenSans-Light';
  src: url(../fonts/OpenSans/OpenSans-Light.ttf);
}
@font-face {
  font-family: 'OpenSans-Medium';
  src: url(../fonts/OpenSans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url(../fonts/OpenSans/OpenSans-Semibold.ttf);
}


@font-face {
  font-family: 'OpenSans-Bold';
  src: url(../fonts/OpenSans/OpenSans-Bold.ttf);
}


body{
  background-color: var(--white) !important;
  font-family: 'OpenSans-Medium';
}

h1{
  font-family: 'Gopher-Medium';
}

main{
  font-family: 'OpenSans-Medium';
}

.mobile{
  display: none;
}
