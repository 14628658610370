#pag-contato{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #area-text{
        width: 100%;
        height: 40vh;
        background-color: var(--gray);
        position: absolute;
        color: var(--white);
        z-index: -1 !important;
        top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px ;

        div{
            width: 70%;
        }
    }

    #area-form{
        margin-top: 150px;
        background-color: var(--white);
        width: 70%;
        min-height: 400px;
        border: 1px solid var(--gray-transparent);
        box-shadow: 4px 4px 50px #000000a0;
        display: flex;
        justify-content: space-between;

        form{
            padding: 100px;
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            div{
                display: flex;
                margin-bottom: 15px;
                border: 1px solid var(--green);
                padding: 5px;
                align-items: center;
                border-radius: 5px;
                width: 90%;

                label{
                    font-size:small;
                    color: var(--gray-transparent-08);
                }
                input, textarea{
                    border: none;
                    background: none;
                    padding: 5px;
                    border-radius: 5px;
                    width: 100%;
                }   
                input:focus, textarea:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: initial;
                  }
                  textarea{
                    height: 100px;
                    resize: none;
                  }

                img{
                    width: 20px;
                    height: auto;
                    margin-right: 10px;
                }
            }
            .button-dark{
                width: 80%;
                padding: 8px 0;
            }
        }
    }

    
    .social-media{
        background-color: var(--green);
        min-height: 400px;
        width: 40%;
        padding: 50px;
        color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3{
            width: 100%;
        }

        .informations{
            margin-top: 50px;
            display: flex;
            align-items: center;
            margin-bottom:10px;

            p{
                margin: 0;
            }
            img{
                width: 20px;
                margin-right: 10px;
            }
        }

        .links{
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items:end;

            .icons{
                margin-top: 30px;
                img{
                    width: 30px;
                    margin-right: 30px;
                }
            }
        }
    }
}

@media screen and (min-width: 900px)and (max-width: 1300px) {
    form{
        padding: 20px !important;
        justify-content: center;
        width: 50% !important;
    }
    .social-media{
        width: 50% !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    #area-text{
        top: 50px !important;
    }
    #area-form{
        flex-direction: column;
        
        form{
            width: 100% !important;
            padding: 30px !important;
        }
        .social-media{
            width: 100%;
        }
    }
}


@media screen and (max-width: 600px) {
    #pag-contato{
        #area-text{
            top: 50px;
        }
        #area-form{
            width: 90%;
            justify-content: center;
            flex-direction: column;
            form{
                width: 100%;
                padding: 20px;
            }
            .social-media{
                width: 100%;
            }
        }
    }    
}