.carrossel {
    position: relative;
    width: 100%;
    max-width:80%;
    margin: auto;
    overflow: hidden;
  }


  
  .carrossel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carrossel-images.mobile{
    display: none;
  }
  
  .carrossel-images img {
    width: 100%;
    display: none;
  }
  
  .carrossel-images img.active {
    display: block;
  }
  
  .carrossel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color:var(--yellow);
    color: black;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .carrossel-button.prev {
    left: 10px;
  }
  
  .carrossel-button.next {
    right: 10px;
  }
  .carrossel-text{
        position: absolute;
        top: 50px;
        left: 80px;
        max-width: 40%;



    p{
        font-size: 20px ;
    }
  }

  @media screen and (max-width: 1200px){
    .carrossel {

        max-width:90%;
      }
    .carrossel-text{
        position: absolute;
        top: 50px;
        left: 80px;
        max-width: 40%;

    p{
        font-size: 16px ;
    }
  }

  }


  @media screen and (max-width: 900px){
    .carrossel {

        max-width:100%;
      }
    .carrossel-text{
        position: absolute;
        top: 30px;
        left: 50px;
        max-width: 42%;
  }

  }

  @media screen and (max-width: 730px){
    .carrossel {
        max-width:80%;
      }
    .carrossel-text{
        position: absolute;
        top: 30px;
        display: flex;
        align-items: center;

        p{
           display: none;
        }
  }

  
  .carrossel-images.mobile{
    display: initial;
  }
  
  .carrossel-images.desktop{
    display: none;
  }

  }