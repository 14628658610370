.banner{
    width: 100%;
    object-fit: cover;
    margin-top: 80px;
    height: 200px;
    z-index: -1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    h1{
      position: absolute;
      color: var(--white);
      font-family: "Gopher-Light";
      letter-spacing: 5px;
      border-left: 5px solid var(--green);
      padding: 0 20px;
    }

    img{
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

#equipment-area{
  display: flex;
    width:  1250px;
    margin: 0 auto;
    flex-wrap: wrap;
}

/***/
.area-text{
    width:  1250px;
    font-size:20px ;
    margin: 80px auto;
    text-align: justify;
  
    p{
      margin-top: 50px;
     
    }
  }
  .card-equipment{
    width: 1250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 5px 5px 30px var(--gray-transparent);
    margin-bottom: 50px;
    border-top: 6px solid var(--green);
  }
  .area-img {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between !important;
    width: 55%;
    height: 500px;
    img{
      max-width: 70%;
      margin: 20px 0 ;
      max-height: 350px;
    }
  }
  
  .area_botoes{
    width: 100%;
    display: flex;
    justify-content: space-around;
    a{
      width: 45%;
      text-align: center;
      padding: 8px ;
      font-size: 16px;
    }
  }
  .list-area{
    width: 40%;
    background-color: var(--gray);
    color: var(--gray);
    padding: 30px 0 ;
    box-shadow: 5px 5px 30px var(--gray-transparent);
    color: var(--white);
    border-radius: 20px;
  }
  
  .datasheet{
    font-size: 15px;
    padding: 0 10px;
    margin: 0;
  }
  .datasheet li {
    padding: 10px;
    border-bottom: 1px solid #ffffff1f;
  }
  
  .datasheet li:last-child{
    border: none;
    margin: none;
  }
  
  .datasheet h6{
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .datasheet p{
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 13px;
    color: var(--green);
  }
  
  .datasheet  li p{
    padding: 0px;
  }
  
  .name-equipment{
    font-size: 30px;
    color: var(--gray);
    text-align: center;
  }
  
  /*IMPLEMENTOS*/
  .card-implementos{
    width: 31%;
    margin-right: 2%;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 5px 5px 20px var(--gray-transparent);
    margin-bottom: 50px;
    border-top: 6px solid var(--green);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .area-img{
        height: 200px ;
        width: 100%;
        display: flex;
        flex-direction: column;

        img{
            max-height: 200px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .list-area{
        width: 95%;
        margin: 20px auto;
        border-radius: 10px;
        padding: 10px 0;
        background-color: var(--white);
        box-shadow: none;
        li{
            padding: 5px 0 !important;
        }
        h6{
            color: var(--gray);
            font-weight: bold;
        }
        p{
            font-size: 15px;
        }
        h6, p{
            margin: 0 ;
        }
    }
}
.card-implementos:last-child{
    margin-right: none;
}

@media screen and (max-width:1300px) {
  .area-text{
    width:  90%;
  }
  #equipment-area{
    width: 90%;
  }
  .card-equipment{
    width: 90% !important;


    img{
      max-width: 80%;}

      .list-area{
        width: 45%;
      }

      .area-img{
        width: 50%;
      }
  }

  .card-implementos{
    width: 31%;
    padding: 10px;

    .area_botoes{
      display: flex;
      flex-direction: column;

      a{
        width: 95%;
        margin: 0 auto;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .banner{
    margin-top: 50px;
  }
  .area-text{
    width:  90% !important;
    font-size:16px ;
    margin: 50px auto;
    text-align: justify;
  
    p{
      margin-top: 50px;
    }
  }

  .card-equipment{
    width: 90% !important;
    flex-direction: column;
    margin: 0 auto;
    padding: 30px;
    margin-bottom: 30px;
  }

  .list-area{
    width: 100% !important;
  }

  .area-img{
    width: 100% !important;
    margin-bottom: 20px;
    height: auto !important;

  }
  .card-implementos{
    width: 47%;
    margin: 0 auto;
    margin-bottom: 50px;
    border-top: 6px solid var(--green);
    display: flex;
    flex-direction: column;

    h2{
      font-size: 25px !important;
    }

    .list-area{
        li{
            padding: 5px 0 !important;
        }
        h6{
            font-size: 13px !important;
        }
        p{
            font-size: 14px;
        }
        h6, p{
            margin: 0 ;
        }
    }
}

}

@media screen and (max-width: 600px) {
  .card-implementos{
    width: 100%;

  }
  .area_botoes{
    flex-direction: column-reverse;
    a{
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .card-equipment{

    padding: 10px !important;
  }
}


