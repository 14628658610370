#apresentacao-incial{
    background: url(../images/ladingPage/rolo-compactador.png) no-repeat center/100%;
    background-attachment: fixed;
    background-position: start ;
    background-size: cover;
    margin-top: 0 !important;

    #title-pag{
       margin: 0 auto;
       border: 1px solid var(--gray-transparent);
       .name-equipment{
        width: 87%;
        text-align: end;
        margin-top: 120px;
            h1{
                color: var(--white);
                margin: 0;
                font-size: 50px;
            }
            p{
                background-color: var(--yellow);
                display:inline;
                margin: 0;
                color: var(--gray);
                padding:0 15px; 
                border-radius: 10px;
                font-size: 25px;
            }
       }
    }
    #seta{
        position: absolute;
        bottom: 50px;
        left: 50%;  
        animation: animation-infinite 5s infinite;
        img{
            
            width: 50px;
            transform: rotate(90deg) !important;
        }
    }
    .area-text{
        padding: 0;
        margin: 0 auto;
        p{
            margin: 0 auto;
            width:90%;
            margin-top: 90vh;
            background-color: var(--yellow);
            color: var(--black);
            display: flex;
            align-items: center;
            padding: 50px;
            margin-bottom: 200px;
            box-shadow: 5px 5px 30px var(--black);
        }
    }
    

}

#data-sheet{
    background-color: black;
    color: var(--white);
    padding-top: 100px;

    h2{
        text-align: center;
        background-color: var(--yellow);
        padding: 10px 0;
        color: black;
        margin: 0;
        text-transform: uppercase ;
    }

    #slide-ladingPage{
        height: auto !important;
        padding: 0;

        .carousel-item{
           margin: 0 auto;
           width: 965px;
        }

        img{
            max-width: 1600px !important;
            height: auto;
            margin: 0 auto;
        }

        .seta{
            width: 150px;
        }
        .carousel-caption{
            animation: none;    
            top: 100px;
            left: 50%;

            h3{
                text-align: start ;
                font-size: 35px;
                width: 33%;
                span{
                    color: var(--yellow);
                }
            }
          
            p{
              margin: 0;
              margin-bottom: 40px;
              text-align: start;
              width: 50%;
              font-size: 20px;
              font-family: 'OpenSans-Medium';
              line-height: 24px;
              color: white;
            }
          
          }

    }
}

.esp-tecnica{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: white;
    padding: 80px 0;
    text-align: center;
    h1{
        width: 90%;

    }

    .button-yellow{
        margin-top: 20px;
    }

}

#ladingPage{
    background: none !important;
    box-shadow: none;
    position: absolute;
    top: 0;
    width: 95%;
    margin: 0 auto ;

    #equipamentos{
        background: black;
    }

    .areaSocialMedia li{
        
          background-color: black;
    }

    .popUpWhatsApp{
        animation: animation-infinite 5s alternate;
         img{
          box-shadow: 5px 5px 20px black ;
          
       }
      
        p{
          background-color: black;
          box-shadow: 3px 3px 30px var(--yellow);
        }
      
    }
    
   
}
#footer{
    background-color: black;
    #lading-page-footer{
        border-top: 3px solid var(--yellow);
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        background-color: black;
        margin: 0 auto;
    }
}

@media screen and (max-width: 900px) {

    #lading-page-footer{
        width: 100%;
    }
    #ladingPage{
        background: black !important;
        box-shadow: none;
        position: absolute;
        top: 0;
        width: 100%;
        margin: 0 auto ;
    }
    #apresentacao-incial{

        #title-pag{
            margin: 0 auto;
            border: 1px solid var(--gray-transparent);
            .name-equipment{
             width: 100% !important;
             text-align: center !important;
             margin-top: 50px !important;
                 h1{
                     color: var(--white);
                     margin: 0;
                     font-size:40px !important;
                 }
                 p{
                     background-color: var(--yellow);
                     display:inline;
                     margin: 0;
                     color: var(--gray);
                     padding:0 15px; 
                     border-radius: 10px;
                     font-size: 20px !important;
                 }
            }
         }
         .area-text{
            padding: 0;
            margin: 0 auto;
            p{
                margin: 0 auto;
                width:95%;
                margin-top: 80vh;
                background-color: var(--yellow);
                color: var(--black);
                display: flex;
                align-items: center;
                padding: 20px;
                margin-bottom: 100px;
                box-shadow: 5px 5px 30px var(--black);
            }
        }
    }

    .desktop{
        display: none;
    }

   



}

@media screen and (max-width: 700px) {

    #data-sheet{
        padding-top: 0 !important ;

        h2{
            font-size: 18px !important;
        }

        #slide-ladingPage{
            margin-top: 20px !important;

        
            .seta{
                width: 50px !important;
            }
            .carousel-caption{
                animation: none;    
                top: 0px !important;
                left: 20px !important;
                right: 0;
                bottom: 0;

                h3{
                    text-align: start ;
                    font-size: 22px !important;
                    width: 100% !important;
                    span{
                        color: var(--yellow);
                    }
                }
            
                p{
                margin: 0;
                margin-bottom: 40px;
                text-align: start;
                width: 100%;
                font-size: 14px !important;
                font-family: 'OpenSans-Medium';
                line-height: 24px;
                color: white;
                }
            
            }
        }
    }
}