
#topo{
  img{
    min-width: 100%;
    object-fit: cover;
  }
  .carousel-item{
    height: 500px;
    margin-top: 80px;
  
    img{
      height: 500px !important;
    }
  }
}

/**FORMATAÇAO DOS SLIDES**/
.carousel-caption{
    animation: animation-vertical 3s;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
  
    p{
      margin: 0 auto;
      margin-bottom: 40px;
      width: 45%;
      font-size:40px;
      border-radius: 20px;
      line-height: 45px;
      font-family: 'Gopher-Medium';
      color: var(--white);
    }
  
    .button-green, .button-dark, .button-clear{
      text-transform: uppercase;
      padding: 5px 30px;
      font-size: 18px;
    }
  
  }
  
  #symbol-animation{
    width: 100px;
    display: block;
    position: relative;
    top: 200px;
  }
  

  
  .text-green{
    color: var(--green);
  }
  
  /**TEXTO MECANIZANDO**/
  #inicio{
    display: flex;
  }
   #presentation{
    width: 280px;
    margin-right: 20px;
    text-align: justify;
  }
  #presentation h2{
    border-left: 4px solid var(--green);
    padding-left: 15px;
    margin-bottom: 40px ;
  }
  #presentation p{
    min-height: 230px;
  }
  #f_slide{
    h1{
      width: 45%;
      margin: 0 auto;
      color: var(--green);
      font-size: 35px;
      animation: mudaCor 5s infinite;
      font-family: "OpenSans-Medium";
      padding: 5px 0;
      border-bottom: 3px solid var(--green);
      background-color: var(--gray-transparent);
      border-radius: 10px;
    }
    p{
      margin-top: 20px;
      font-size: 25px;
      line-height: 35px;
    }
  }

  @keyframes mudaCor{
    0%{
      color: var(--white);
      border-bottom: 3px solid var(--white);
    }
    25%{
      color: var(--green);
      border-bottom: 3px solid var(--green);
    }
    50%{
      color: var(--white);
      border-bottom: 3px solid var(--white);
    }
    75%{
      color: var(--green);
      border-bottom: 3px solid var(--green);
    }
    100%{
      color: var(--white);
      border-bottom: 3px solid var(--white);
    }
  }
  /**/
  #area-initial{
    width: 1250px;
    margin: 0 auto;
    margin-top: 80px ;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  #best-equipment{
    width: 80%;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--green-transparent);
    display: flex;
    align-items: center;
    justify-content: center;    

    .best-equipment-area{
      display: flex !important;
      align-items: center;
      justify-content: center !important;
      flex-wrap: nowrap;
      width: 100%;
  
      .card{
        width: 23%;
        border-top: 3px solid var(--green);
        height: 360px;
        background-color: var(--white);
        box-shadow: 3px 3px 5px var(--gray-transparent); 
        color: var(--white);
        margin-right: 20px;

        .area-img{
          width: 80%;
          height: 200px;
          margin: 20px auto;
          display: flex;
          justify-content: end !important ;
          img{
            max-width: 100%;
            margin: 0px auto;
            
          }
          
        }
  
        p{
          color: var(--gray);
          text-align: center;
          padding: 0 10px;
          text-transform: uppercase;
          font-size: 16px;
          min-height: 50px;
        }
  
        a{
          width: 70%;
          border-radius: 10px;
          margin: 0 auto;
          padding: 5px 10px !important;
          font-family: 'OpenSans-Medium';
          text-align: center;
          display: block;
          font-size: 16px;
        }
      }
  
      .card-equiment:last-child{
        margin-right: 0;
       
      }
    }
  }
  
  /*most researched equipment*/
  #most-researched{
    margin:0px auto;
    padding: 30px 0;
    .researched{
      margin: 0 auto;
      width: 1250px;
    }
    h2{
      padding-left: 20px;
      border-left: 5px solid var(--green);
    }
  
    .area-equip{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;
    }
    .equip{
      width: 32%;
      margin-bottom: 20px;
      margin-left: 10px;
      border-radius: 10px;
      text-align: center;
      background-color: var(--white);
      box-shadow: 2px 5px 20px var(--gray-transparent);
      border-top: 5px solid var(--green);

      .content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        height: 300px;

        .descricao{
          width: 59%;
          height: 280px;
          padding: 10px 0;
          text-align: start;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p{  
            display: block;
          }
        }
        .area-imge{
          width: 40%;
          img{
            width: 100%;
          }
        }
      }
      h3{
        padding: 5px 0;
        font-size: 20px;
        border-top-left-radius:10px ;
        border-top-right-radius:10px ;
        margin: 0;
      }
      .text-green{
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  
  /*ANIMATIONS - CLIENTS*/
  
  #clients{
    text-align: center;
    margin: 0 auto;
    background-color: var(--white);
    position: relative;
    margin-bottom: 100px;
    margin-top: 50px;
    border-radius: 10px;
    width: 1250px;
    background-color: var(--gray);
    padding: 30px;
    
    h2{
      padding: 10px;
      color: var(--white);
    }

  }
  .slider{
    border-bottom: 5px solid var(--green);
      background-color: var(--white);
  }
  .customer-slide{

    img{
      width: 170px;
      border-radius: 10px;

    }
   
  }
  h1, h2, h3{
    font-family: 'Gopher-Medium';
  }
  
  h4, h5{
    font-family: 'Gopher-Light';
  }
  

  @media screen and (max-width : 1300px) {

    /*Slides*/
    .carousel-caption{
      animation: animation-vertical 3s;
      top: 30%;
      p{
        font-size: 35px;
        width: 50% !important;
      }
    }

    #area-initial{
      display:flex;
      width: 95%;
      flex-direction: column;
      align-items: center;

      #presentation{
        margin: 0 auto;
        text-align: justify;
        width: 90%;
        margin-bottom: 50px;
      }

      #presentation p{
        min-height: 130px;
      }
    }
    .animation-button{
      width: 50%;
      margin: 0 auto;
    }
    #best-equipment{
      width: 100%;
      p{
        font-size: 15px;
      }
    }
    #most-researched{
      margin:0px auto;
      padding: 30px 0;
      width: 100%;
      .researched{
        margin: 0 auto;
        width: 90%;
      }
      .area-equip{
        justify-content: center;
      }
      .equip{
        height: 300px;
        width: 330px;
      }
    }
    #clients{
      width: 90%;
      padding: 10px;
      .slider{
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 900px){
    #topo .carousel-item{
      margin-top: 0 !important;
    }
    #best-equipment{
      width: 90%;
      
      .best-equipment-area{
        width: 100%;
        flex-wrap: wrap !important;
        justify-content: center !important;
      }
        .card{
          width: 250px !important;
          margin: 0 auto !important;
          margin-bottom: 30px !important;

          .area-img{
            width: 80% !important;
            height: 200px !important;
            margin: 20px auto;
            display: flex;
            justify-content: end !important ;
            
            img{
              max-width: 100%;
              margin: 0px auto;
            }
          }
      }
    }
    #clients{
      display: none;
    }

    #most-researched{
      margin:0px auto;
      padding: 30px 0;
      width: 100%;
      .researched{
        margin: 0 auto;
        width: 90%;
      }
      .area-equip{
        justify-content: center;
      }
      .equip{
        height: 400px;
        width: 300px;
        .content{
          flex-direction: column-reverse;
          justify-content: center;
          height: 400px;

          img{
            width: 100px !important;
            height: auto;
          }

          .descricao{
            width: 100%;
           
          }
        }
      }
    }
  }


@media screen and (max-width: 700px){
    #topo{
      max-height: 400px;
      .carousel-item{
        height: 400px;
        
      
        img{
          min-width: 100%;
          object-fit: cover;
          max-height: 400px !important;
        }
      }
    }
    #f_slide{
      width: 90% !important ;
      top: 16% ;
      margin: 0 auto;
      h1{
        width: 45%;
        margin: 20px auto;
        color: var(--green);
        font-size: 35px;
        animation: mudaCor 5s infinite;    
        background-color: var(--gray-transparent);
        border-radius: 10px;
    
      }
      p{
        font-size: 22px;
        line-height: 28px;
      }
    }
  

    .carousel-caption{
      animation: animation-vertical 3s;
      top: 25% ;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 400px !important;
    
      p{
        width: 90% !important ;
        font-size:25px ;
        line-height: 30px ;
        margin-bottom: 20px ;
      }
    
      .button-green, .button-dark, .button-clear{
        padding: 8px 20px ;
        font-size: 13px ;
        color: var(--white);
        
      }
    
    }

    #area-initial{
      margin-top: 35px;
      padding: 0px;
      h2{
        text-align: start !important;
      }

      p{
        padding-bottom: 30px;
      }
    }

    #clients{
      display: none;
    }

    #most-researched{
      margin:0px auto;
      padding: 0;
      width: 100%;

      h2{
        width: 85%;
        margin: 0 auto;
      }

      .researched{
        margin: 0 auto;
        width: 100%;
      }

      .area-equip{
        justify-content: space-around;
      }
      .equip{
        height: 400px;
        padding: 10px !important;
        width: 280px !important;
        
        .content{
          flex-direction: column-reverse;
          justify-content: space-around;
          height: auto;
          padding: 10px;
         .area-imge{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10%;
            img{
              width: 150px !important;
              height: auto;
            }
         }

          .descricao{
            width: 100%;
            height: 210px;
          }
        }
      }
    }
  }