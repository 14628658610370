#area-logos{
  margin-bottom: 100px;
  
  img.animation{
    width: 400px;
  }
}

section{
  max-width: 2000px;
  margin: 0 auto;
}

#AboutUs{
  #area-initial-ab{
    background: var(--white);
    color: var(--gray);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
  max-height: 1000px;
    width: 100%;
    
    h4{
      width: 60%;
      text-align: center;
      text-transform: uppercase;
      line-height: 40px;
      font-weight: bold;
  
      span{
        color: var(--green);
      }
    }
    
    h1{
      font-size: 80px;
      text-align: center;
    }
  }

  .sobre-nos{
    height: 100vh;
    background: url(/src/images/patio-background_1.webp) no-repeat center/100%;
    display: flex;
    align-items: end;
    padding-bottom: 100px ;
    color: var(--white);
    max-height: 1000px;

    .text{
      background-color: var(--green-transparent-08);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 50px;
    }
    p{
      width: 80%;
      font-size: 16px;
      margin-left: 10px;
      text-align: justify;
          }

  }

  .distribuidores{
    padding: 80px 150px;
    text-align: center;

    p{
      width:60%;
      margin: auto;
      padding: 30px 0 50px 0;
      font-size: 18px;
    }

    .img-distribuidores{     
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    img{
      width: 200px;
    }
  }

  .history{
    background: url(/src/images/EstruturaBH.webp) no-repeat center/100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    height: 656px;
    .text{
      height: auto;
      width: 40%;
      background-color: var(--gray-transparent-08);
      color: var(--white);
      padding: 20px 40px;
      text-align: justify;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
    }
  }


  /*Missão visão e valores*/
  .valores{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap ;
    .timeLine{
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      img{
        width: 100%;
        margin: 0 auto;
        max-width: 1270px;
      }
    }

    .container-mvv{
      width: 30%;
      background-color: var(--green);
      padding: 50px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .text{
        width: 100%;
        max-width: 450px;
        height: 250px;
        border-top: 4px solid var(--white);
        background-color: var(--gray);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-size: 16px;
        text-align: justify;
        padding: 30px;
        box-shadow: 3px 3px 20px var(--gray-transparent-08);
        border-radius: 10px;
      }
    }

    
    #valores, #missao, #visao{
      padding: 10px;
      display: flex;
    }

    nav{
      width: 100%;
      display: flex;
      justify-content: center;
      ul{
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
        li{
          padding: 5px 20px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-family: 'Gopher-Medium';
          font-size:20px;
          cursor: pointer;
          background-color: var(--green);
          border: 1px solid var(--white);
          color: var(--gray);
          margin-right: 2px;

          p{
            margin-bottom: 5px ;
            border-bottom: 1px solid var(--gray);
          }
          
        }
        li.active{
          background-color: var(--gray);
          color:var(--white);
          border: 1px solid var(--green-transparent);
          p{
            border-bottom: none;
          }
        }
        li:hover{
          color: var(--white);
        }
        li.active:hover{
          color: white;
        }
      }
    }
  }

  
  
}

@media screen and (min-width:900px) and (max-width: 1300px){
  .history{
    height: auto !important;
    padding: 0 !important;
    background: url(/src/images/EstruturaBH.webp) no-repeat top/100 !important;
    .text{
      width: 60% !important;
      min-height:auto !important;
      padding:  30px !important;
  
    }
  }
  .distribuidores{
    width: 90%;
    margin-bottom: 30px !important;
    padding: 30px !important;
  }

  .container-mvv{
    width: 100% !important;
    flex-direction: column ;
    
  .text{
    padding: 10px !important;
  }
}

.timeLine{
  width: 100% !important;
}
}

@media screen and (max-width:900px) {
  #AboutUs{
    #area-initial-ab{
      margin-bottom: 50px;
      height: auto !important ;

      #area-logos{
        width: 70%;
        margin: 0 auto;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 60%;
        }
      }
      h4{
        width: 60%;
        font-size: 16px;
        line-height: 25px;
        font-weight: bold;
    
        span{
          color: var(--green);
        }
      }
    }
    .distribuidores{
      padding: 30px;
      
      p{
        font-size: 14px;
      }

      .img-distribuidores{
        width: 90%;
        img{
          width: 24%;
        }
      }
    }
      .sobre-nos{
        height: auto !important;
        background: url(/src/images/patio-background_1.webp) no-repeat center;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 !important ;
        color: var(--white);
        max-height: auto;
    
        .text{
          background-color: var(--green-transparent-08);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
        }
        p{
          width: 80%;
          font-size: 14px;
          margin-left: 10px;
          text-align: justify;
              }
    
      }
      .history{
        background: url(/src/images/EstruturaBH.webp) no-repeat center;
        height: auto !important;
        padding: 0;
        .text{
          width: 100%;
          height: auto !important;
          font-size: 14px;
          margin: 0 !important;
          padding: 20px;
        }
      }
      .container-mvv{
        width: 100% !important;
        flex-direction: column ;
        p{
          font-size: 12px;
        }

      .text{
        padding: 10px !important;
        
      }
    }
    
    .timeLine{
      width: 100% !important;
    }

    }


}