
#PaginaNaoEncontrada{
  display: flex;
  height: 50vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  h1{
    color: var(--green);
  }
}
/*BUTTONS*/
.button-green{
  background-color: var(--green);
  padding: 5px 30px;
  text-decoration: none;
  color: var(--white);
  border-radius: 10px;
  font-size: 14px;
  font-family: 'OpenSans-Semibold';
}
.button-clear{
  background-color: var(--white);
  padding: 5px 30px;
  text-decoration: none;
  color: var(--green);
  border-radius: 10px;
  font-size: 14px;
  font-family: 'OpenSans-Semibold';
}

.button-dark{
  background-color: var(--gray);
  padding: 5px 30px;
  text-decoration: none;
  color: var(--white);
  border-radius: 10px;
  font-size: 14px;
  font-family: 'OpenSans-Semibold';
}

.button-yellow{
  background-color: var(--yellow);
  padding: 5px 30px;
  text-decoration: none;
  color: var(--gray);
  border-radius: 10px;
  font-size: 14px;
  font-family: 'OpenSans-Semibold';
}

.animation-button{
  background-color: var(--gray);
  padding: 5px 0;
  text-decoration: none;
  color: var(--white);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  animation: animation-button 3s infinite;
}

@keyframes animation-button{
  0%{
      box-shadow: 10px 10px 80px var(--green);
  }
  25%{
      box-shadow: none;
      color: var(--white);
  }
  50%{
      box-shadow: 10px 10px 80px var(--green);
  }
  75%{
      box-shadow: none;
      color: var(--white);
  }
  100%{
      box-shadow: 10px 10px 80px var(--green);
  }
}

.mobile{ display: none;}

/*TEXTS*/

.text-gray{
  color: var(--gray);
}
.border-white{
  border-bottom: 3px solid var(--white);
}

ul {
  list-style-type: none;
}


/*HEADER STYLE*/
header{
  position: fixed;
  height: 80px;
  top: 0; left: 0; right: 0;
  background-color: var(--gray);
  box-shadow: 5px 5px 20px var(--gray-transparent );
  padding: 0px 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
}
.hidden{
  display: none;
}

.area-logo img{
    width: 130px;
}

header .navbar ul li{
  position: relative;
  float: left;
}
header .navbar ul li a{
 color: var(--white);
 text-decoration: none;
 font-size: 13px;
 display: block;
}
#principal.navbar{
  top: 10px;
}

#principal.navbar ul li a{
  padding: 20px 10px;
  text-transform: uppercase;
  font-size: 14px;

}

#principal.navbar ul li a::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left:0;
  background-color: var(--green);
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
 }

 #principal.navbar ul li a:hover::before{
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
 }

 #topo.navbar ul li  {
  padding: 0 5px;
 }
 #topo.navbar ul li a:hover {
    color: var(--green);
 }

 /**DROPDOWN**/
 .navbar ul li ul{
  position: absolute;
  left: 0px;
  width: 300px;
  background-color: var(--gray);
  display: none;
  padding: 10px;
 }
 .navbar ul li ul li{
  margin: auto;
  width: 100%;
 }
 .navbar ul li ul li:hover a{
  background-color: var(--gray);
  color:var(--green);
 }

.navbar ul li:hover ul{
  display: initial;
}
#menu-bar{
  display: none;
}

label{
  display: initial;
}
header label img{
  cursor: pointer;
  border-radius: 0;
}

header label{
  display: none;
}
.dropdown{
  img{
   display: none;
  }
}


/*FOOTER*/

footer{
  width: 100%;
  background-color: var(--gray);
  color: white;
  font-size: 14px;

  #footer-container{
      display: flex;
      width: 1200px;
      margin: auto;
  }

  section{
      width: 33%;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
  }

  #image-lokmax{
      margin-bottom: 30px;

      img{
          width: 150px;
      }
      #area-img{
          width: 100%;
      }
      

  }
  #social-media{
      width: 100%;
      img{
          width: 40px;
          margin-right: 10px;
      }

      ul{
          display: flex;
          padding: 0;
          
      }
  }
  #contact{
      
      li{
          margin-bottom: 10px;
          
      }
  }
  h5{
      margin-bottom: 20px;
      font-size: 16px;
  }

  a{
      color: var(--white);
      text-decoration: none;
  }
  ul{
      padding: 0;}
  #map{
      margin-top: 40px;
      img{
          width: 10px !important;
          height: 10px;
      }
      li{
          display: flex;
          align-items: center;
      }
  }
}

/*Links - canto da tela e pop up do wpp*/
.areaSocialMedia{
  position: fixed;
  right: 0;
  top: 45%;
  z-index: 10000;

  li{
    background-color: var(--green);
    padding: 5px;
    margin-bottom: 10px;
    width: 35px;
  }
  img{
    width: 100%;
  }
}

.popUpWhatsApp{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  animation: animationPopUp 3s;
  z-index: 1000 ;

  img{
    width: 60px;
    margin-left: 10px;
    box-shadow: 5px 5px 20px var(--gray);
    border-radius: 50%;
  }

  p{
    margin: 0;
    background-color: var(--gray);
    padding: 5px 10px;
    border-radius: 10px;
    color: var(--white);
    box-shadow: 3px 3px 30px var(--green-transparent);
    font-size: 14px;
  }
}

@keyframes animationPopUp {
  0%{
    transform: translateY(50px);
  }
  100%{
    transform: translateY(0px);
  }
}

@keyframes main-animation-slide{
  from {
    transform: translateX(300px);
  } 
  to {
    transform: translateX(0);
  }
}

@keyframes animation-vertical{
  from {
    transform: translateY(300px);
  } 
  to {
    transform: translateY(0);
  }
}

@keyframes animation-infinite{
  0%{
    transform: translateY(20px);
  }
  25%{
    transform: translateY(0px);
  }
  50%{
    transform: translateY(20px);
  }
  75%{
    transform: translateY(0px);
  }
  100%{
    transform: translateY(20px);
  }
}

@media screen and (min-width:900px )and (max-width: 1300px) {
  header{
    padding: 0 10px;

    #topo.navbar{
      top: 0;
      padding: 0;
    }
  }

  #footer-container{
    width: 90% !important;

    section{
      width: 30% !important;
    }
  }
  
}


@media screen and (max-width: 900px) {
  .animation-button{
    width: 100% !important;
  }

  #principal.navbar ul li a{
    text-transform:capitalize;
    font-family: 'OpenSans-Semibold';
  }

  header {
    height: 55px;
    label {
      display: initial;
    }
  }



  .area-logo img{
    width:90px;
}

  main{
    margin-top: 55px ;
  }

  header .navbar{
    display: none;
    position: absolute;
    right: 0;
    margin-top: 40px;
    background-color: var(--white);
    box-shadow: 2px 2px 50px var(--gray-transparent-08);
    width: 100%;
  }
  .closeIcon{
    display: none;
  }
  .dropdown{
    img{
      display: initial;
      margin-left: 10px;
    }
  }

  .dropdown img.rotate{
      transform: rotate(180deg);
  }

  #menu-bar:checked ~ .navbar{
    display: initial;
   
  }
  #menu-bar:checked ~ .closeIcon{
    display: initial;
    animation: rotate 1s ease-in-out;
  }

  @keyframes rotate {
    0%{
      transform: rotate(45deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  #menu-bar:checked ~ .active{
    display: none;
  }

  header .navbar ul li a{
    color: var(--gray);}

  header .navbar ul li{
    width: 91%;
    border-top: 1px solid #00000022;
    a{
      padding: 15px 0 !important;
    }
  }

  header .navbar ul li:last-child{
    border-bottom: none;

  }

  #topo.navbar{
    display: none !important;
  }

  .hidden{
    display: initial;
  }
  #principal.navbar{
    padding: 0 !important;
  }

  .navbar ul li ul{
    position: relative;
    left: 0px;
    width: 100%;
    background-color: var(--white);
    display: none;
    padding: 0px;
   }

  header .navbar ul li ul li {
    background-color: var(--white);
    padding: 0 20px;
  }

  footer{
  
    #footer-container{
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
    }
  
    section{
        width: 250px !important;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
    }

  }  
  .desk-hidden{
    display: none;
  }
  .mobile{
    display: initial;

    img{
      max-height: 90vh;
      object-fit:contain;
    }
    
  }
}


